import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MetabaseService } from '@app/shared/_services/metabase.service';
import { UtilsModule } from "../../../shared/utils/utils.module";

@Component({
  selector: 'dcar-quality',
  standalone: true,
  imports: [CommonModule, UtilsModule],
  templateUrl: './quality.component.html',
  styleUrl: './quality.component.css'
})
export class QualityComponent {

  iframeurl: string;

  constructor(private metabaseService: MetabaseService) { 
    this.metabaseService.gettoken().subscribe((data) => {
      console.log(data);
      this.iframeurl = data.url;
    });
  }

  ngOnInit() {
    
  }

}
