import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class MetabaseService {

  constructor(private _httpBaseService: HttpBaseService) { }

  gettoken(){
      return this._httpBaseService.get('metabase/get-token');
  }
}
